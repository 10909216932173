<template>
  <div class="box-company-info">
    <div>重置密码</div>
    <form>
      <div v-if="errmsg!==''" class="alert alert-danger" style="width: 488px;margin-left: 220px">{{ errmsg }}</div>
      <div class="box-group">
        <label class="control-label">新密码 <span class="require">*</span></label>
        <div>
          <input v-model="password" placeholder="新密码" type="password" class="form-control"
                 name="password" @blur="checkPassword">
          <span class="error-info">
          <span style="display: inline-block;width: 492px">{{ passworderror }}</span>
        </span>
        </div>
      </div>
      <div class="box-group">
        <label class="control-label">确认新密码 <span class="require">*</span></label>
        <div>
          <input v-model="repassword" placeholder="确认新密码" type="password" class="form-control"
                 name="repassword" repeat-password="password" @blur="checkRepassword">
          <span class="error-info">
          <span>{{ repassworderror }}</span>
        </span>
        </div>
      </div>
      <div class="box-group" style="margin-top: 30px">
        <label/>
        <button type="submit" class="btn btn-one" @click.prevent="resetPwd">重置密码</button>
      </div>
    </form>
    <common-dialog id="resetPwdDialog" :message="resetmsg"/>
  </div>
</template>

<script>
import CommonDialog from '@/components/cn/CommonDialog'

export default {
  components: {
    CommonDialog
  },
  name: 'CompanyInfo',
  data () {
    return {
      password: '',
      repassword: '',
      passworderror: '',
      repassworderror: '',
      isValid: false,
      resetmsg: '',
      errmsg: ''
    }
  },
  computed: {
    urlParams () {
      const arr = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&')
      const obj = {}
      for (const item of arr) {
        const param = item.split('=')
        obj[param[0]] = param[1]
      }
      return obj
    }
  },
  beforeCreate () {
    this.$i18n.locale = 'zh-cn'
  },
  methods: {
    resetPwd () {
      if (this.checkForm()) {
        this.$store.dispatch('SetLoadingStatus', true)
        const params = {}
        params.NewPassword = this.password
        params.LoginUserName = this.urlParams.UserName
        params.UpdateTime = new Date(parseInt(this.urlParams.Time))
        this.$store.dispatch('ResetPasswordByEmail', params).then(() => {
          this.errmsg = ''
          this.resetmsg = 'resetsuccessful'
          this.$bvModal.show('resetPwdDialog')
          this.$store.dispatch('SetLoadingStatus', false)
        }).catch((err) => {
          if (err.ErrorCode === 6010) {
            this.errmsg = '该用户不存在'
          }
          if (err.ErrorCode === 6012) {
            this.errmsg = '新密码与原密码不能相同'
          }
          this.$store.dispatch('SetLoadingStatus', false)
        })
      }
    },
    checkPassword () {
      if (!this.password) {
        this.passworderror = '新密码不能为空。'
        return false
      } else if (!/^[a-zA-Z0-9]{6,20}$/.test(this.password)) {
        this.passworderror = '新密码由6-20个英文字母或数字组成。'
        return false
      } else {
        this.passworderror = ''
        return true
      }
    },
    checkRepassword () {
      if (!this.repassword) {
        this.repassworderror = '请再次输入新密码。'
        return false
      } else if (this.password !== this.repassword) {
        this.repassworderror = '两次输入的密码不一致。'
        return false
      } else {
        this.repassworderror = ''
        return true
      }
    },
    checkForm () {
      return this.checkPassword() && this.checkRepassword()
    }
  }
}
</script>

<style lang="scss" scoped>

$cell-margin-right: 20px;

.box-company-info {
  height: 534px;
  background-color: #ffffff;
  border-radius: 20px;
  color: #535353;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0 20px 30px;
}

.box-company-info > div:first-child {
  margin: 40px 0 20px 0;
  font-size: 20px;
  font-weight: bold;
}

.box-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
}

.box-group > label {
  width: 200px;
  font-size: 16px;
  text-align: right;
}

input {
  width: 488px;
  height: 35px;
  margin-left: $cell-margin-right;
  background-color: #EDEDED;
}

select {
  width: 160px;
}

.checkbox-style > input {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #707070;
  margin-right: 5px;
  margin-left: 0;
}

.btn-one {
  width: 492px;
  height: 35px;
  border-radius: 8px;
  background-color: #4285F4;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.form-control {
  background-color: #EDEDED;
}

.error-info {
  margin-left: $cell-margin-right;
}

.hint-info {
  color: #CCCCCC;
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: $cell-margin-right;
}

.hint-info > img {
  width: 6px;
  height: 6px;
  margin-right: 5px;
}

/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
.require {
  color: red;
}
</style>
